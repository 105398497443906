import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
    return (
        <div className='cards'>
            <h1>Übersicht der Inhalte</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/SmartHome.jpg'
                            text='SmartHome Steuerung und Oberfläche'
                            label='Smarthome'
                            path='/smarthome'
                        />
                        <CardItem
                            src='images/Frau-mit-Smartphone.jpg'
                            text='Laden Sie sich die neusten Apps herunter'
                            label='Apps'
                            path='/apps'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/Bauernhof.jpg'
                            text='Mehr über die Geschichte Baustettens'
                            label='Hofnamen'
                            path='/hofnamen'
                        />
                        <CardItem
                            src='images/cloud.jpg'
                            text='Flexibler Cloud-Speicher'
                            label='Cloud'
                            path='/cloud'
                        />
                        <CardItem
                            src='images/bibliothek.jpg'
                            text='Freie Enzyklopädie'
                            label='Wikiserver'
                            path='/wiki'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Cards;