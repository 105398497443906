import React, { Component } from 'react';
import '../../App.css';
import CardItem from '../CardItem';
import HeroSection from '../HeroSection';
import '../Cards.css';
import Cards from '../Cards';



class SmartHome extends Component {
    render() {
        window.scrollTo(0, 0)
        return <div>

            <HeroSection
                title="SmartHome"
                imgpath="images/SmartHome.jpg"
            />
            <Cards />

        </div>;
    }
}

export default SmartHome;


