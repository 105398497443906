import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { Icon, Button } from '@material-ui/core';

import React, { Component } from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Imagetext from '../Imagetext';

const images = [
    {
        original: 'images/ss2_mediawiki.jpg',
    },
];

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#56B52A',
            dark: '#00B500',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

class Wiki extends Component {
    render() {
        window.scrollTo(0, 0)
        return <div>

            <HeroSection
                title="Wikiserver"
                imgpath="images/bibliothek.jpg"
            />
            <div className="content">
                {/*<Cards />*/}
                <Imagetext
                    title="Wikiserver"
                    images={images}
                    subtitle="Content | Collaboration | Plattform"
                    interval="5000">

                    <p>
                        Die Software MediaWiki wird von mehreren zehntausend Websites und mehreren tausend Unternehmen und Organisationen genutzt.
                        Sie wird insbesondere von der Wikipedia, wie auch dieser Website eingesetzt.
                        MediaWiki hilft Dir beim Erarbeiten, Organisieren und Publizieren von Wissen.
                        Die Software ist mächtig, mehrsprachig, frei und offen, erweiterbar, anpassbar, zuverlässig sowie kostenfrei erhältlich.
                        Erfahre mehr und ob MediaWiki für Dich nützlich sein kann.
                    </p>
                    <ThemeProvider theme={theme}>
                        <a href="https://wiki.betz-group.de/" className="nextcloud-link">
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CloudUpload />}
                                variant="contained"
                                color="primary"
                                type="submit"

                            >
                                Go to Wikiserver
                        </Button>
                        </a>
                    </ThemeProvider>
                </Imagetext>
            </div>



        </div>;
    }
}

export default Wiki;


