import React, { Component, } from 'react';
import '../../App.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import HeroSection from '../HeroSection';
import '../Cards.css';
import IconText from '../IconText';
import TextContainer from '../TextContainer';
import axios from 'axios';


//const position = [48.201306, 9.889076];
const greenIcon = new L.icon({
    iconUrl: 'images/Marker_Icon.png',
    iconSize: [50, 50], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [33, 60], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

class Hofnamen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: []
        }
    }
    componentDidMount() {
        //get request
        axios.get('https://www.betz-group.de/Backend/gethofname.php').then(res => {

            this.setState({ data: res.data });
        });

    }




    render() {
        window.scrollTo(0, 0)
        return <div>

            <HeroSection
                title="Hofnamen"
                imgpath="images/Bauernhof.jpg" />





            <div className="content">

                <TextContainer
                    title="Baustetter Hofnamen"
                />

                <div className="Box">
                    <div className="icon-Box">´

                        <IconText
                            title="Aktuell"
                            itemone="gepflegt"
                            itemtwo="Erweiterbar" />
                        <IconText
                            title="Ortsnah"
                            itemone="Baustetten"
                            itemtwo="Oberschwaben" />
                        <IconText
                            title={this.state.data.length + " Höfe"}
                            itemone="Generation"
                            itemtwo="Tradition & Urspung" />
                        <IconText
                            title="Interaktiv"
                            itemone="Leicht zu verwenden"
                            itemtwo="Scroll & Drag" />
                    </div>

                </div>

                <div className="Map">
                    <MapContainer
                        center={[48.205769, 9.887420]}
                        className="Map-Container"
                        zoom={15.2}
                        scrollWheelZoom={true}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        {this.state.data.map((result) => {
                            return (
                                <Marker position={[result.Latitude, result.Longtitude]} icon={greenIcon}>
                                    <Popup>
                                        {result.Familienname + ": " + result.Hofnamen}
                                    </Popup>
                                </Marker>
                            )


                        })}
                    </MapContainer>
                </div>
            </div >

        </div >;
    }
}

export default Hofnamen;



