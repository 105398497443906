import React, { Component } from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import TextContainer from '../TextContainer';



class Projekte extends Component {
    render() {
        window.scrollTo(0, 0)
        return <div>
            <HeroSection
                title="Projekte"
                imgpath="images/projekte.jpg"
            />
            <div className="content">
                <Cards />
            </div>



        </div>;
    }
}

export default Projekte;


