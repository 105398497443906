import React, { Component } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';
import SmartHome from './components/pages/SmartHome';
import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';
import NotFound from './components/pages/NotFound';
import Footer from './components/Footer'
import Kontakt from './components/pages/Kontakt';
import Hofnamen from './components/pages/Hofnamen';
import Apps from './components/pages/Apps';
import Cloud from './components/pages/Cloud';
import Projekte from './components/pages/Projekte';
import Wiki from './components/pages/Wiki';





//function App() {
class App extends Component {

  render() {

    return (
      <>
        <HashRouter basename={'/'}>
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/smarthome' component={SmartHome} />
            <Route path='/impressum' component={Impressum} />
            <Route path='/datenschutz' component={Datenschutz} />
            <Route path='/kontakt' component={Kontakt} />
            <Route path='/hofnamen' component={Hofnamen} />
            <Route path='/apps' component={Apps} />
            <Route path='/cloud' component={Cloud} />
            <Route path='/projekte' component={Projekte} />
            <Route path='/wiki' component={Wiki} />
            <Route path="/404" component={NotFound} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </HashRouter >
      </>
    );
  }
}

export default App;