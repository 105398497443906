import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { Icon, Button } from '@material-ui/core';

import React, { Component } from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Imagetext from '../Imagetext';

const images = [
    {
        original: 'images/nextcloud-homescreen.png',
    },
    {
        original: 'images/nextcloud-login.png',
    },
    {
        original: 'images/nextcloud-files.png',
    },
];

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#56B52A',
            dark: '#00B500',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

class Cloud extends Component {
    render() {
        window.scrollTo(0, 0)
        return <div>

            <HeroSection
                title="Cloud"
                imgpath="images/cloud.jpg"
            />
            <div className="content">
                {/*<Cards />*/}
                <Imagetext
                    title="Cloud"
                    images={images}
                    subtitle="Content | Collaboration | Plattform"
                    interval="5000">

                    <p>
                        Neben dem Speichern von Dateien lassen sich Inhalte mit anderen Nutzern, Servern und Nextcloud-Instanzen teilen.
                        Die Suche berücksichtigt den Text in Dokumenten und ein Index wird anhand der Informationen aus Bildern und Dokumenten aufgebaut.
                    </p>

                    <p>
                        Nextcloud kann auch für Video-Telefonate einsetzen und Dokumente mit Collabora bearbeiten, das auf LibreOffice basiert.
                        Entsprechend versteht Nextcloud die Microsoft-Office-Formate DOC, DOCX, PPT, PPTX und das Open-Dokument-Format (ODF).
                    </p>
                    <ThemeProvider theme={theme}>
                        <a href="https://cloud.betz-group.de/index.php/login" className="nextcloud-link">
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CloudUpload />}
                                variant="contained"
                                color="primary"
                                type="submit"

                            >
                                Go to Nextcloud
                        </Button>
                        </a>
                    </ThemeProvider>
                </Imagetext>
            </div>



        </div>;
    }
}

export default Cloud;


