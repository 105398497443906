import React, { Component } from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';


class Home extends Component {


  render() {
    window.scrollTo(0, 0)
    return (
      <>
        <HeroSection
          title="Betz-Group"
          subtitle="Das ist die offizielle Webseite der Familie Betz"
          imgpath="images/arme.jpg"
        />
        <Cards />
      </>
    );
  }
}

export default Home;